// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');


$primary: #802989; /* MAIN COLOR */
$secondary: #cf3570; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-size: 1.5em;
	font-family: 'Josefin Sans', sans-serif;

}
.top-pad {
	margin-top: 120px;
	@media(max-width:767px){
		margin-top: 80px;
	}
}
nav {
	z-index: 1000;
	background: $primary;
}
.navbarFixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
}
.navbar-default {
	// background: $primary
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {
		>li>a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;
			font-size: 1em;
			border-radius: 5px;
			text-transform: uppercase;

			@media (max-width: 767px) {
				margin-top: 5px;
				padding: 2px;
				display: inline-block;
			}

			&:focus,
			&:active {
				background: transparent;
				color: $blk;
				outline: 0;
			}

			&:hover {
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e66655+1,83cdc1+40,83cdc1+53,83cdc1+100 */
				background: $secondary;
				color: $wht;

				@media (max-width: 1024px) {
					background: transparent;
					color: $blk;
					outline: 0;
				}
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
// start sections
h1 {
	font-size: 2.5em;
	font-family: 'Abril Fatface', cursive;

}

.cta {
	background: $wht;
	padding: 25px 35px;
	margin: 5px;
	font-size:1.2em;
	@media(max-width:767px){
		font-size: 1em;
		padding: 15px 25px;
	}
}

section {
	padding: 50px 0;
	position: relative;
	text-align: center;
}
.section-a {
	background-image:url('../img/bg1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: 40% 35%;
	// height: 100vh;
	margin-top: 100px;
	.logo-section-a {
		margin-top: 15%;

		@media (max-width: 767px){
				margin-top: 2%;
		}
	}
	@media(max-width:1024px){
		background-attachment: scroll;
	}
	@media(max-width:767px){
		margin-top: 70px;
	}
}
.section-b {
	background:url('../img/bg2.jpg')no-repeat top center/cover fixed;
	padding: 250px 0;
	.text-container {
		padding: 50px;
		background: rgba(128,41,137,.8);
	}
	h1 {
		color: $wht;
		font-size: 2.5em;
		@media(max-width:767px){
			font-size: 1.8em;
		}
	}
	p{
		font-size: 2em;
		color: $wht;
		@media(max-width:767px){
			font-size: 1em;
		}
	}
	@media(max-width:1024px){
		background-attachment: scroll;
	}
	@media(max-width:767px){
		padding: 50px 0;
	}
}
.section-c {
	background:url('../img/bg3.jpg')no-repeat top center/cover fixed;
	padding: 250px 0;
	@media(max-width:1024px){
		background-attachment: scroll;
	}
	@media(max-width:767px){
		padding: 50px 0;
	}
	.text-container {
		padding: 50px;
		background: rgba(255,255,255,.5);
	}
	h1 {
		color: $secondary;
		font-size: 2.5em;
		text-transform: uppercase;
		@media(max-width:767px){
			font-size: 1.5em;
		}
	}
	.cta {
		background: $secondary;
		color: $wht;
	}
}
.section-d {
	background:url('../img/bg4.jpg')no-repeat top center/cover fixed;
	padding: 250px 0;
	color: $wht;
	@media(max-width:1024px){
		background-attachment: scroll;
	}
	@media(max-width:767px){
		padding: 50px 0;

	}
	.text-container {
		padding: 50px;
		background: rgba(128,41,137,.8);
	}
	h1 {
		font-size: 2.5em;
		@media(max-width:767px){
			font-size: 1.8em;
		}
	}
	p{
		font-size: 2em;
		@media(max-width:767px){
			font-size: 1em;
		}
	}
}
// ends sections
/** END LOGIN FORM **/

footer {
	padding: 20px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}
